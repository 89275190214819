.crossfade-container {
    display: block;
    overflow: hidden;
  }
  
  .crossfade-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 6s ease-in-out; /* Adjust the duration as needed */
  }
  
  .crossfade-image.visible {
    opacity: 1;
  }
