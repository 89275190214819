@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}
  
body {
    color: rgb(var(--foreground-rgb));
    background: white
}
  
section {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
